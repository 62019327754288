
import { Options, Vue } from 'vue-class-component';
import Pagination from '@/components/layout/Pagination.vue';
import NoData from '@/components/layout/NoData.vue';
import { PreviewOpen, Edit, Delete } from '@icon-park/vue-next';

@Options({
    components: {
        Pagination, NoData,
        PreviewOpen, Edit, Delete
    },
    data(){
        return {
            categories: []
        }
    },
    watch:{
    },
    mounted(){
        this.getCategories();
    },
    methods: {
        getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = res.data;
            })
        },
        handlerEdit(item: any){
            this.$router.push(`/content/category/edit/${item.id}`);
        },
        goCategory(item: any){
            if(item.type === 'page'){
                this.$router.push(`/content/category/${item.id}/pages/edit`);
            } else if(item.type === 'equipment'){
                this.$router.push(`/content/category/${item.id}/equipments`);
            } else {
                this.$router.push(`/content/category/${item.id}/articles`);
            }
        }
    }
})

export default class ContentLayout extends Vue {}
