<template>
    <div class="content-layout">
        <div class="layout-container">
            <div class="right-view">
                <div class="no-data-container" v-if="$route.path === '/content'">
                    <no-data text="暂无内容，点击左侧分类，查看分类下的内容" />
                </div>
                <router-view v-else />
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Pagination from '@/components/layout/Pagination.vue';
import NoData from '@/components/layout/NoData.vue';
import { PreviewOpen, Edit, Delete } from '@icon-park/vue-next';

@Options({
    components: {
        Pagination, NoData,
        PreviewOpen, Edit, Delete
    },
    data(){
        return {
            categories: []
        }
    },
    watch:{
    },
    mounted(){
        this.getCategories();
    },
    methods: {
        getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = res.data;
            })
        },
        handlerEdit(item: any){
            this.$router.push(`/content/category/edit/${item.id}`);
        },
        goCategory(item: any){
            if(item.type === 'page'){
                this.$router.push(`/content/category/${item.id}/pages/edit`);
            } else if(item.type === 'equipment'){
                this.$router.push(`/content/category/${item.id}/equipments`);
            } else {
                this.$router.push(`/content/category/${item.id}/articles`);
            }
        }
    }
})

export default class ContentLayout extends Vue {}
</script>

<style lang="scss" scoped>
.content-layout{
    @extend .flex-column;
    .layout-container{
        @extend .flex-row-between;
        width: 100%;
        height: 100%;
        .right-view{
            @extend .flex-column;
            width: 100%;
            margin: 0 15px;
            .no-data-container{
                margin-top: 15px;
                border-radius: $radius;
                background-color: $white;
                box-shadow: $shadow-default;
            }
        }
    }
}

</style>